<template>
    <div id="Brands" class="pl-1 m-0" style="min-height: 100vh; border-left: 4px solid white">
        <div class="row">
            <div class="col-sm-12 col-lg-6 py-3 py-lg-5">
                <p class="manrope-title">
                    Precios SPA
                </p>
            </div>

            <div class="col-sm-12 col-lg-6 py-0 pb-3 pb-lg-0 py-lg-5  p-0 m-0">
                <div class="row p-0 m-0  justify-content-end align-items-end">
                    <div class="col-12 col-md-4 col-lg-3 p-0 px-3 px-md-3 px-lg-0 m-0 pl-2 pl-md-0 pr-2 mr-0 mr-lg-3 justify-content-end">
                        <li-select @change="filterElements"
                            class="opensans-bold"
                            label="Filtrar por:"
                            variant="secondary"
                            :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                            v-model="filter"
                            full>
                        </li-select>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 p-0 px-3 px-md-0 m-0 my-3 my-md-0 pl-2 pl-md-0 justify-content-end">
                        <button @click.stop="prices_modal = true"
                            class="btn btn-white btn-pill w-100 opensans-bold"
                            type="button">
                            Añadir Elemento
                        </button>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 position-relative">
                        <input @keyup.enter="filterElements"
                            class="custom_input bg-dark opensans-bold text-white pl-3"
                            type="text"
                            v-model="search_query"
                            placeholder="Buscar"
                            />
                        <img class="position-absolute"
                            style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                            :src="lupa_icon" />
                    </div>
                </div>
                

            </div>

            <div class="col-12" v-if="loading && !fetch_error">
                <li-spinner size="sm"/>
            </div>

            <div class="col-12" v-if="!loading && fetch_error">
                <p>
                    Ha ocurrido un error cargando los datos, intente más tarde.
                </p>
            </div>

            <div class="col-12" v-if="empty_search">
                <p>
                    No existen elementos con esos parametros de búsqueda.
                </p>
            </div>
            
            <div class="col-12 font-table" v-if="!loading && !fetch_error && !empty_search">
                <li-table :data="prices_data" 
                    :allow_headers="prices_headers"
                    pagination_off>
                    <template v-slot:section="data">
                        {{ data.item.section == 'prices_home' ? 'inicio' : data.item.section == 'prices_packages' ? 'Paquete' : data.item.section == 'treatments_home' ? 'Tratamientos' : data.item.section == 'services_vip' ? 'Servicios VIP' : ''  }}
                    </template>
                    <template v-slot:price="data">
                        $ {{ formatAmount(data.item.price) }}
                    </template>
                    <template v-slot:edit="data">
                        <button @click.stop="selectPrice('edit', data.item)"
                            class="btn btn-outline-warning btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Editar
                        </button>
                    </template>
                    <template v-slot:delete="data">
                        <button @click.stop="selectPrice('delete', data.item)" v-if="data.item.active"
                            class="btn btn-outline-danger btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Eliminar
                        </button>
                        <button @click.stop="selectPrice('activate', data.item)" v-if="!data.item.active"
                            class="btn btn-outline-primary btn-pill p-0 interbold"
                            style="width: 90px;"
                            type="button">
                            Activar
                        </button>
                    </template>
                </li-table>
            </div>
        </div>

        <!---- Paginator ----->

        <div class="col-12" v-if="!fetch_error && !empty_search">
            <div class="row justify-content-between">

                <div class="col-12 col-md-5 col-lg-4 d-flex justify-content-center justify-content-lg-start align-items-center px-0 mt-2 mt-md-0">
                    <span class="mr-3 opensans-bold">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="rows"
                        class="form-control bg-secondary text-white border-0"
                        :disabled="page > 1"
                        />
                    
                    <li-select @change="filterElements"
                        class="pl-1"
                        variant="secondary"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc">
                    </li-select>
                </div>

                <div class="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="form-control bg-secondary text-white border-0 mx-4 opensans-bold"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!-- Modal-->

        <li-modal :show_modal="prices_modal" :max_width="1200" class="px-3">
                <div class="col-12">
                    <h3 v-if="!selected_id">
                        Añadir Elemento
                    </h3>
                    <h3 v-if="selected_id">
                        Editar Elmento
                    </h3>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Título"
                        type="text"
                        placeholder="Título del elemento"
                        v-model="title"
                        full>
                    </li-input>
                    <span class="text-danger"  v-if="errors.title">
                        {{ errors.title }}
                    </span>
                </div>

                <div class="col-12 col-md-6  p-2">
                    <li-select  label="Sección"
                        :options="[{id: 'prices_home', label: 'Inicio'}, { id: 'prices_packages', label: 'Paquetes'},{id: 'treatments_home', label: 'Tratamientos'},{id: 'services_vip', label: 'Servicios VIP'}]"
                        v-model="section"
                        variant="secondary"
                        full/>
                    <span class="text-danger" v-if="errors.section">
                        {{ errors.section }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Precio"
                        type="number"
                        placeholder="Título del elemento"
                        v-model="element_price"
                        full>
                    </li-input>
                    <span class="text-danger"  v-if="errors.element_price">
                        {{ errors.element_price }}
                    </span>
                </div>

                <div class="col-12 col-md-6  p-2">
                    <li-input label="Orden:"
                        type="number"
                        placeholder="Orden"
                        v-model="order"
                        full>
                    </li-input>
                    <span class="text-danger"  v-if="errors.order">
                        {{ errors.order }}
                    </span>
                </div>

                <div class="col-12 p-2 opensans-bold">
                    <li-input label="Descripción:"
                        type="text"
                        placeholder="Descripción"
                        v-model="description"
                        full>
                    </li-input>
                    <span class="text-danger"  v-if="errors.description">
                        {{ errors.description  }}
                    </span>
                </div>

                <div class="col-12 p-2 opensans-bold">
                    <li-input label="Descripción adicional 1"
                        type="text"
                        placeholder="Descripción"
                        v-model="description_1"
                        full>
                    </li-input>
                    <span class="text-danger"  v-if="errors.description_1">
                        {{ errors.description_1  }}
                    </span>
                </div>

                <div class="col-12 p-2 opensans-bold">
                    <li-input label="Descripción adicional 2"
                        type="text"
                        placeholder="Descripción"
                        v-model="description_2"
                        full>
                    </li-input>
                    <span class="text-danger"  v-if="errors.description_2">
                        {{ errors.description_2  }}
                    </span>
                </div>

                <div class="col-12 p-2 opensans-bold">
                    <li-input label="Descripción adicional 3"
                        type="text"
                        placeholder="Descripción"
                        v-model="description_3"
                        full>
                    </li-input>
                    <span class="text-danger"  v-if="errors.description_3">
                        {{ errors.description_3  }}
                    </span>
                </div>

                <div class="col-sm-6 col-lg-3 mt-5 opensans-bold">
                    <div style="width: 250px;">
                        <p>
                            Imagen
                        </p>
                        <li-dropfile _id="element_image" v-if="!preview"
                            @charged="checkFile" />
                                
                        <div class="d-flex flex-column  py-2" v-if="preview">
                            <img class="img-fluid"
                                :src="preview" />
                            <button @click.stop="preview = null"
                                class="btn btn-primary mt-2"
                                type="button">
                                Cambiar
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-12 d-flex justify-content-end mt-2">
                    <button @click.stop="createEditPrice"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="clearFields"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="delete_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h4>
                        ¿Esta seguro de eliminar este elemento? 
                        <br>
                        Esta acción no puede revertirse
                    </h4>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="deletePrice"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="clearFields"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="activate_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h4>
                        ¿Esta seguro de activar este elemento? 
                    </h4>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="activatePrice"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="clearfields"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>
        
    </div>
</template>

<script>

    import lupa_icon from 'ASSETS/icons/lupa_icon.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon,
                arrow_left,
                loading: true,
                fetch_error: false,
                search_query: null,
                empty_search: false,
                prices_modal: false,
                delete_modal: false,
                activate_modal: false,
                prices_headers: [
                    { key: 'title', label: 'Título'},
                    { key: 'section', label: 'Sección'},
                    { key: 'list_item_1', label: 'Descripción 1'},
                    { key: 'list_item_2', label: 'Descripción 2'},
                    { key: 'list_item_3', label: 'Descripción 3'},
                    { key: 'price', label: 'Precio'},
                    { key: 'edit', width: '10'},
                    { key: 'delete', width: '10'}
                ],
                prices_data: [],
                selected_id: null,
                title: null,
                thumbnail: null,
                section: 'home',
                description: null,
                description_1: null,
                description_2: null,
                description_3: null,
                element_price: null,
                order: null,
                preview: null,
                errors: {
                    title: null,
                    thumbnail: null,
                    description: null,
                    description_1: null,
                    description_2: null,
                    description_3: null,
                    section: null,
                    element_price: null,
                    order: null
                },
                filter: 'active',
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2',
            }
        },
        computed: {
            ...mapGetters({
                prices: 'page_prices/getPrices',
                price: 'page_prices/getPrice',
                last_page: 'page_prices/getLastPage',
                total_pages: 'page_prices/getTotalPages'
            })
        },
        methods: {
            //helpers
            checkFile(file){
                console.log('file', file)
                this.preview = file.url
                this.thumbnail = file.file.name
            },
            clearFields() {
                for(let error in this.errors) {
                    this.errors[error] = null
                }

                this.selected_id =null
                this.title =null
                this.thumbnail =null
                this.section = 'prices_home'
                this.description = null
                this.description_1 =null
                this.description_2 =null
                this.description_3 =null
                this.preview = null
                this.element_price = null
                this.order = null

                this.prices_modal = false
                this.delete_modal = false
                this.activate_modal = false

            },
            async filterElements(){
                let search = ''
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('page_prices/list', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('page_prices/list', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('page_prices/list', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.prices === null) {
                    this.empty_search = true
                    return
                }

                else {
                    this.empty_search = false
                }
                
               this.prices_data = this.prices.map( price => {
                    return {
                        ...price,
                        edit: '-',
                        delete: '-'
                    }
                })
            },
            async selectPrice(type, data) {
                switch(type) {
                    case 'edit':

                        if(!data.active) {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'No se permite la edición de elementos inactivos',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }

                        this.selected_id = data.id_price
                        await this.$store.dispatch('page_prices/view', { id_price: this.selected_id})
                        this.title = this.price.title
                        this.preview = this.price.image
                        this.element_price = this.price.price.toString();
                        this.section = this.price.section
                        this.description = this.price.description
                        this.description_1 = this.price.list_item_1 ?? null
                        this.description_2 = this.price.list_item_2 ?? null
                        this.description_3 = this.price.list_item_3 ?? null
                        this.order = this.price.order

                        this.prices_modal = true
                        return;
                    case 'delete':
                        this.selected_id = data.id_price
                        this.delete_modal = true
                        return;
                    case 'activate':
                        this.selected_id = data.id_price
                        this.activate_modal = true
                        return;
                    default:
                        console.log('No es un caso valido')
                        return
                }
            },
            async createEditPrice() {

                let complete = true;
                for(let error in this.errors){
                    this.errors[error] = null
                }

                if(_.isEmpty(this.title) || _.isNull(this.title)) {
                    complete = false
                    this.errors['title'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.element_price) || _.isNull(this.element_price)) {
                    complete = false
                    this.errors['element_price'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.description) || _.isNull(this.description)) {
                    complete = false
                    this.errors['description'] = '*Campo obligatorio'
                }

                if(this.section == 'prices_home' || this.section == 'prices_packages') {

                    if(_.isEmpty(this.description_1) || _.isNull(this.description_1)) {
                        complete = false
                        this.errors['description_1'] = '*Campo obligatorio'
                    }

                    if(_.isEmpty(this.description_2) || _.isNull(this.description_2)) {
                        complete = false
                        this.errors['description_2'] = '*Campo obligatorio'
                    }

                    if(_.isEmpty(this.description_3) || _.isNull(this.description_3)) {
                        complete = false
                        this.errors['description_3'] = '*Campo obligatorio'
                    }

                }


                if(complete) {
                    const data = {
                        title: this.title,
                        image: this.thumbnail,
                        price: this.formatAmount(this.element_price, 2),
                        section: this.section,
                        description: this.description,
                        list_item_1: this.description_1,
                        list_item_2: this.description_2,
                        list_item_3: this.description_3,
                        order: this.order
                    }

                    if(this.selected_id) {
                        const payload = {
                            body: data,
                            query: {
                                id_price: this.selected_id
                            }
                        }
                        await this.$store.dispatch('page_prices/update', payload)
                        this.filterElements()
                        this.prices_modal = false
                        this.clearFields()
                    }

                    else {
                        await this.$store.dispatch('page_prices/add', data)
                        this.filterElements()

                        this.clearFields()
                        
                    }
                }
            },
            async deletePrice() {
                await this.$store.dispatch('page_prices/delete', { id_price: this.selected_id, active: false})
                this.filterElements()

                this.delete_modal = false
                this.selected_id = null
            },
            async activatePrice(){
                await this.$store.dispatch('page_prices/delete', { id_price: this.selected_id, active: true})
                this.filterElements()
                this.activate_modal = false
                this.selected_id = null
            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                console.log('next')
                this.page++;
                this.filterElements()
            }
        },
        async created() {
            
            try {
                //lista de marcas
                await this.$store.dispatch('page_prices/list', {datatable: true, page: this.page, rows: this.rows, status: 'active', order_asc: false})
                
                this.prices_data = this.prices.map( price => {
                    return {
                        ...price,
                        edit: '-',
                        delete: '-'
                    }
                })

                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                this.loading = false
                this.fetch_error = true
                console.error(error)
            }
        }
    }
</script>

<style lang="scss" scoped>
    #Brands {
        .custom_input {
            height: 40px;
            border: 1px solid white;
            border-radius: 30px;
            width: 100%;
            &::placeholder{
                padding-left: 15px;
                color: white;
            }
            &:focus{
                outline: none;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        input[type=number] { 
            -moz-appearance: textfield;
            appearance: textfield;
            margin: 0; 

        }
    }
</style>